import { z } from "zod";

export enum MassEmailState {
  DRAFT = "DRAFT",
  READY = "READY",
  SENT = "SENT",
}

const renderedMassEmailSchema = z.object({
  id: z.string().uuid(),
  staffId: z.number(),
  created: z.coerce.date(),
  sent: z.coerce.date().nullable(),
  subjectLine: z.string().min(1),
  body: z.string().min(1),
  state: z.nativeEnum(MassEmailState),
  html: z.string(),
  txt: z.string(),
});

export type RenderedMassEmail = z.infer<typeof renderedMassEmailSchema>;

export const newRenderedMassEmail = (data: unknown): RenderedMassEmail =>
  renderedMassEmailSchema.parse(data);
