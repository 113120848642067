import { Title, useHash } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";

import PatientAppHelp from "src/pages/Help/PatientAppHelp";
import SubscriptionsHelp from "src/pages/Help/SubscriptionsHelp";

type TabState = "subscriptions" | "patientApp";

const Help = () => {
  const [tab, setTab] = useHash<TabState>("subscriptions");

  return (
    <>
      <Title title="Help" />
      <TabContext value={tab}>
        <TabList
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Subscriptions" value="subscriptions" />
          <Tab label="Patient App FAQs" value="patientApp" />
        </TabList>
        <TabPanel value="subscriptions">
          <SubscriptionsHelp />
        </TabPanel>
        <TabPanel value="patientApp">
          <PatientAppHelp />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default Help;
