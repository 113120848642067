import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import { Email, RenderedMassEmail } from "src/models";

type TabState = "html" | "txt";
interface IProps {
  email: Email | RenderedMassEmail;
  patientName?: string;
}
const isMassEmail = (
  email: Email | RenderedMassEmail,
): email is RenderedMassEmail => {
  return (email as RenderedMassEmail).subjectLine !== undefined;
};

const EmailPreview = ({ email, patientName }: IProps) => {
  const [tab, setTab] = useState<TabState>("html");

  const replacePlaceholders = (text: string) => {
    const replacements = {
      "cid:Logo.png": "/static/media/CuraleafClinic_Colour.png",
      "Dear Patient": patientName ? `Dear ${patientName}` : "Dear Patient",
      "cid:CQCLogo.png": "/static/media/CQCLogo.png",
    };

    let replacedText = text;

    for (const [placeholder, value] of Object.entries(replacements)) {
      replacedText = replacedText.replace(placeholder, value);
    }

    return replacedText;
  };

  return (
    <>
      <DialogTitle>
        {isMassEmail(email) ? email.subjectLine : email.subject}
      </DialogTitle>
      <DialogContent>
        <TabContext value={tab}>
          <TabList
            aria-label="Email menu"
            onChange={(_, value: string) => setTab(value as TabState)}
            sx={{ marginBottom: 2 }}
          >
            <Tab label="HTML" value="html" />
            <Tab label="Plain txt" value="txt" />
          </TabList>

          <TabPanel value="html">
            <Box
              dangerouslySetInnerHTML={{
                __html: replacePlaceholders(email.html),
              }}
            />
          </TabPanel>
          <TabPanel value="txt">
            <Box
              dangerouslySetInnerHTML={{
                __html:
                  replacePlaceholders(email.txt).replace(/\n/g, "<br />") ?? "",
              }}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </>
  );
};

export default EmailPreview;
