import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import { AppointmentType } from "src/models/appointment";
import {
  ClinicianAvailability,
  newClinicianAvailability,
} from "src/models/clinicianAvailability";
import { useQuery, useMutation } from "src/query";

export interface IClinicianAvailabilityData {
  appointmentTypes: AppointmentType[];
  clinicianId: string;
  endAt: Date;
  startAt: Date;
}

export const useClinicianAvailabilityQuery = (clinicianId: string) => {
  return useQuery<ClinicianAvailability[]>({
    queryKey: ["clinicianAvailability", clinicianId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/clinician-availability/${clinicianId}/`,
      );

      return response.data.availability.map((data: unknown) =>
        newClinicianAvailability(data),
      );
    },
    enabled: clinicianId !== undefined,
  });
};

export const useCreateClinicianAvailabilityMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IClinicianAvailabilityData) =>
      await axios.post(`/v1/clinician-availability/`, {
        appointmentTypes: data.appointmentTypes,
        clinicianId: data.clinicianId,
        endAt: formatISO(data.endAt),
        startAt: formatISO(data.startAt),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["clinicianAvailability"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};

export const useEditClinicianAvailabilityMutation = (
  availabilityId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IClinicianAvailabilityData) =>
      await axios.put(`/v1/clinician-availability/${availabilityId}/edit/`, {
        appointmentTypes: data.appointmentTypes,
        clinicianId: data.clinicianId,
        endAt: formatISO(data.endAt),
        startAt: formatISO(data.startAt),
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["clinicianAvailability"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};

export const useDeactivateClinicianAvailabilityMutation = (
  availabilityId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(
        `/v1/clinician-availability/${availabilityId}/deactivate/`,
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["clinicianAvailability"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["appointmentSlots"],
      });
    },
  });
};
