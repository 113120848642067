import {
  SelectField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import {
  SubmitButton,
  TextField,
  FormLinks,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import EmailPreview from "src/components/EmailPreview";
import { MassEmailState } from "src/models";
import {
  useMassEmailQuery,
  useUpdateMassEmailMutation,
} from "src/queries/massEmails";

interface IProps {
  unsentEmailId: string;
}

const FormSchema = z.object({
  subjectLine: z.string().min(1),
  body: z.string().min(1),
  state: z.nativeEnum(MassEmailState),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const EditMassEmail = ({ unsentEmailId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: unsentEmail } = useMassEmailQuery(unsentEmailId);
  const { mutateAsync: updateMassEmail } =
    useUpdateMassEmailMutation(unsentEmailId);
  const [previewOpen, setPreviewOpen] = useState(false);

  const methods = useForm<FormType>({
    defaultValues: { subjectLine: "", body: "", state: MassEmailState.DRAFT },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await updateMassEmail(data);
      if (
        data.state === MassEmailState.DRAFT ||
        data.state === unsentEmail?.state
      ) {
        addToast("Mass email updated successfully", "success");
      } else {
        addToast("Mass email placed in sending queue", "success");
        setLocation("/tools/#mass-emails");
      }
    } catch {
      addToast("Try Again", "error");
    }
  };

  const setOpen = () => {
    setPreviewOpen(!previewOpen);
  };

  useEffect(() => {
    if (unsentEmail) {
      methods.reset({
        subjectLine: unsentEmail.subjectLine,
        body: unsentEmail.body,
        state: unsentEmail.state,
      });
    }
  }, [unsentEmail, methods.reset]);

  return unsentEmail === undefined ? (
    <Skeleton />
  ) : (
    <>
      <Title title="Update Mass Email" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <TextField
                fullWidth
                label="Subject Line"
                name="subjectLine"
                required
              />
              <TextField
                fullWidth
                label="Email Body"
                name="body"
                multiline={true}
                rows={8}
                required
              />
              <SelectField
                fullWidth
                options={[
                  { value: MassEmailState.DRAFT, label: "No" },
                  { value: MassEmailState.READY, label: "Yes" },
                ]}
                label="Ready to send"
                name="state"
                helperText="Marking as yes will queue the email to be sent on the next hour. Edits are still possible after marking it as ready, but you must make sure to check the time. Email sending times are 9am, 10am, 11am, 12pm, 1pm, 2pm, 3pm, 4pm, and 5pm"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Update" disableClean={true} />
              <Button
                disabled={
                  methods.formState.dirtyFields.subjectLine ||
                  methods.formState.dirtyFields.body
                }
                onClick={setOpen}
              >
                {methods.formState.dirtyFields.subjectLine ||
                methods.formState.dirtyFields.body
                  ? "Update to preview"
                  : "Preview"}
              </Button>
              <Dialog onClose={setOpen} open={previewOpen}>
                <EmailPreview email={unsentEmail} />
              </Dialog>
              <FormLinks
                links={[{ label: "Back", to: "/tools/#mass-emails" }]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default EditMassEmail;
