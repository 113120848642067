import { ToastContext } from "@curaleaf-international/components";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useContext } from "react";

import {
  Clinician,
  ClinicianAvailability,
  ClinicianRecurringAvailability,
} from "src/models";
import EditRecurringAvailabilityForm, {
  ValidatedType,
} from "src/pages/UpdateClinicianAvailability/RecurringAvailabilityForm";
import {
  useDeactivateClinicianRecurringAvailabilityMutation,
  useEditClinicianRecurringAvailabilityMutation,
} from "src/queries/clinicianRecurringAvailability";

interface IProps {
  clinician: Clinician;
  onClose: () => void;
  recurring: ClinicianRecurringAvailability;
  selectedAvailability: ClinicianAvailability;
}

const EditRecurringAvailability = ({
  clinician,
  onClose,
  recurring,
  selectedAvailability,
}: IProps) => {
  const { mutateAsync: deactivateRecurringAvailability } =
    useDeactivateClinicianRecurringAvailabilityMutation(recurring.id);
  const { mutateAsync: updateRecurringAvailability } =
    useEditClinicianRecurringAvailabilityMutation(recurring.id);
  const { addToast } = useContext(ToastContext);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await updateRecurringAvailability({
        appointmentTypes: data.appointmentTypes,
        dayEnd: data.dayEnd,
        dayStart: data.dayStart,
        endAt: data.endAt,
        recurringType: data.recurringType,
        startAt: data.startAt,
      });
      onClose();
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === "TIME_RANGE_OVERLAP"
      ) {
        addToast("Overlaps existing availability", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  const deleteRecurringAvailability = async () => {
    try {
      await deactivateRecurringAvailability();
      onClose();
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <DialogTitle sx={{ textTransform: "capitalize", paddingBottom: 0 }}>
        Edit Recurring Availability
      </DialogTitle>
      <EditRecurringAvailabilityForm
        clinician={clinician}
        deleteRecurringAvailability={() => deleteRecurringAvailability()}
        editing={true}
        initialValues={{
          appointmentTypes: recurring.appointmentTypes,
          endAt: recurring.endAt ?? null,
          dayEnd: new Date(
            `${selectedAvailability.endAt.toISOString().split("T")[0]}T${recurring.dayEnd}`,
          ),
          recurringType: recurring.recurringType,
          startAt: selectedAvailability.startAt,
          dayStart: new Date(
            `${selectedAvailability.startAt.toISOString().split("T")[0]}T${recurring.dayStart}`,
          ),
        }}
        onClose={onClose}
        onSubmit={onSubmit}
        recurring={recurring}
      />
    </>
  );
};

export default EditRecurringAvailability;
