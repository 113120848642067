import {
  AppointmentCountdown,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  Value,
  formatDateTime,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { addMinutes, isFuture, isPast } from "date-fns";
import { ChangeEvent, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link as WLink, Redirect, useLocation } from "wouter";
import * as z from "zod";

import { AppointmentCreditState, AppointmentStatus } from "src/models";
import {
  useAppointmentQuery,
  useConfirmAppointmentMutation,
  useReleaseAppointmentMutation,
  usePatientsAppointmentCreditsQuery,
  useImportAppointmentPaymentMutation,
} from "src/queries";

const MINUTES_TO_BOOK = 30;

interface IProps {
  appointmentId: string;
}

const MakeAppointmentPayment = ({ appointmentId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: appointment } = useAppointmentQuery(appointmentId);
  const { data: credits } = usePatientsAppointmentCreditsQuery(
    appointment?.patientId,
  );
  const { mutateAsync: confirm } = useConfirmAppointmentMutation(appointmentId);
  const { mutateAsync: releaseAppointment, isPending } =
    useReleaseAppointmentMutation(appointmentId);
  const { mutateAsync: importAppointmentPayment } =
    useImportAppointmentPaymentMutation(appointmentId);
  const [paymentOption, setPaymentOption] = useState("pay");

  const FormSchema = z
    .object({
      paymentIntentId: z.string().regex(/^pi_.*|$/),
      reason: z.string(),
    })
    .superRefine((value, ctx) => {
      if (paymentOption === "free" && value.reason.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Reason required",
          path: ["reason"],
        });
      } else if (
        paymentOption === "import" &&
        value.paymentIntentId.length === 0
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Payment Intent ID required",
          path: ["paymentIntentId"],
        });
      }
    });

  type FormType = z.input<typeof FormSchema>;

  const methods = useForm<FormType>({
    defaultValues: { reason: "", paymentIntentId: "" },
    resolver: zodResolver(FormSchema),
  });

  const handlePaymentOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentOption((event.target as HTMLInputElement).value);
    methods.setValue("paymentIntentId", "");
    methods.setValue("reason", "");
  };

  const handleReleaseAppointment = async () => {
    try {
      await releaseAppointment();
      addToast("Appointment cancelled", "success");
      setLocation(`/patients/${appointment?.patientId}/new-appointment/`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        addToast("Cannot find appointment", "error");
      } else {
        addToast("Try again", "error");
      }
    }
  };

  const onSubmit = async (data: FormType) => {
    if (paymentOption === "import" && !appointment!.price.isZero()) {
      try {
        await importAppointmentPayment({
          paymentIntentId: data.paymentIntentId,
        });
        addToast("Payment imported", "success");
        setLocation(`/appointments/${appointmentId}/`);
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "INVALID_APPOINTMENT"
        ) {
          addToast("Invalid appointment", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "APPOINTMENT_NOT_PAYABLE"
        ) {
          addToast("Cannot attach payment to non-pending appointment", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "APPOINTMENT_PAYMENT_ALREADY_EXISTS"
        ) {
          addToast("Payment already exists for this appointment", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "PAYMENT_ATTACHED_TO_OTHER_APPOINTMENT"
        ) {
          addToast("This payment is attached to another appointment", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "PAYMENT_AMOUNT_MISTMATCH"
        ) {
          addToast("Payment amount does not match appointment price", "error");
        } else {
          addToast("Try again", "error");
        }
      }
    } else {
      try {
        let method = paymentOption;
        let creditId = null;
        if (!["free", "pay"].includes(paymentOption)) {
          method = "credit";
          creditId = paymentOption;
        }
        await confirm({
          creditId,
          method: appointment!.price.isZero() ? "zeroPrice" : method,
          reason: data.reason,
        });
        if (paymentOption === "pay" && !appointment!.price.isZero()) {
          await navigator.clipboard.writeText(appointmentId);
          window.open("https://dashboard.stripe.com/payments/new", "_blank");
          addToast("Awaiting payment confirmation", "success");
        } else {
          addToast("Appointment confirmed", "success");
        }
        setLocation(`/appointments/${appointmentId}/`);
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "APPOINTMENT_NOT_FOUND"
        ) {
          addToast("Could not find appointment", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "APPOINTMENT_NOT_PENDING"
        ) {
          addToast("Appointment must be pending to be confirmed", "error");
        } else if (
          axios.isAxiosError(error) &&
          error.response?.data.code === "INVALID_METHOD"
        ) {
          addToast("Invalid method", "error");
        } else {
          addToast("Try again", "error");
        }
      }
    }
  };

  if (
    appointment !== undefined &&
    (isPast(addMinutes(appointment.bookedOn, MINUTES_TO_BOOK)) ||
      appointment.status === AppointmentStatus.ABANDONED)
  ) {
    return <Redirect to={`/appointments/${appointment.id}/`} />;
  } else {
    return (
      <>
        <Title
          title="Confirm Appointment"
          breadcrumbs={[
            { label: "Patients", to: "/patients/" },
            {
              label: `${appointment?.patientName}`,
              to: `/patients/${appointment?.patientId}/`,
            },
            {
              label: "Add New Appointment",
              to: `/patients/${appointment?.patientId}/new-appointment/`,
            },
            { label: "Appointment Payment" },
          ]}
        />
        <Card>
          <CardContent>
            {appointment !== undefined ? (
              <AppointmentCountdown
                beforeTimerLabel="Time remaining to complete this booking: "
                end={addMinutes(appointment.bookedOn, MINUTES_TO_BOOK)}
                start={appointment.bookedOn}
              />
            ) : null}
            <TableContainer sx={{ marginBottom: 2 }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Price</TableCell>
                    <TableCell>
                      <Value currency={appointment?.price} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Patient</TableCell>
                    <TableCell>
                      <Link
                        component={WLink}
                        to={`/patients/${appointment?.patientId}/`}
                      >
                        <Value text={appointment?.patientName} />
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Clinician</TableCell>
                    <TableCell>
                      {" "}
                      <Value text={appointment?.clinicianName} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell sx={{ textTransform: "capitalize" }}>
                      <Value
                        text={appointment?.type.toLowerCase().replace("_", "-")}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell>
                      <Value dateTime={appointment?.startAt} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>End</TableCell>
                    <TableCell>
                      {appointment !== undefined
                        ? formatDateTime(
                            addMinutes(appointment.startAt, appointment.length),
                          )
                        : null}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {appointment?.price.isZero() ? null : (
              <FormControl>
                <FormLabel>Payment option</FormLabel>
                <RadioGroup
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handlePaymentOptionChange(event)
                  }
                  value={paymentOption}
                >
                  {credits
                    ?.filter(
                      (credit) =>
                        credit.appointmentId === null &&
                        credit.appointmentType === appointment?.type &&
                        credit.state === AppointmentCreditState.ACTIVE &&
                        isPast(credit.activeFrom) &&
                        isFuture(credit.activeTo),
                    )
                    .map((credit) => (
                      <FormControlLabel
                        key={credit.id}
                        control={<Radio />}
                        label={`Use credit - ${credit.currentHistoryReason}`}
                        value={credit.id}
                      />
                    ))}
                  <FormControlLabel
                    control={<Radio />}
                    label="Take payment"
                    value="pay"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Import payment"
                    value="import"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Free of charge"
                    value="free"
                  />
                </RadioGroup>
              </FormControl>
            )}
          </CardContent>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {paymentOption === "free" ? (
                <CardContent sx={{ paddingTop: 0 }}>
                  <TextField fullWidth label="Reason" name="reason" />
                </CardContent>
              ) : paymentOption === "import" ? (
                <CardContent sx={{ paddingTop: 0 }}>
                  <TextField
                    fullWidth
                    label="Payment Intent ID"
                    name="paymentIntentId"
                    placeholder="pi_"
                    required
                  />
                </CardContent>
              ) : null}
              <Divider />
              <CardActions>
                <SubmitButton
                  disabled={
                    appointment === undefined ||
                    credits === undefined ||
                    appointment.status === AppointmentStatus.ABANDONED
                  }
                  label="Confirm"
                />
                <LoadingButton
                  disabled={appointment?.status === AppointmentStatus.ABANDONED}
                  onClick={handleReleaseAppointment}
                  loading={isPending}
                  color="warning"
                  variant="contained"
                >
                  Cancel
                </LoadingButton>
              </CardActions>
            </form>
          </FormProvider>
        </Card>
      </>
    );
  }
};

export default MakeAppointmentPayment;
