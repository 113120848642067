import { zodDecimal } from "@curaleaf-international/components";
import { Decimal } from "decimal.js";
import * as z from "zod";

import { FormulaState } from "src/models/formulaStateHistory";

export enum Form {
  CAPLET = "CAPLET",
  CAPSULE = "CAPSULE",
  CREAM = "CREAM",
  DISPERSIBLE_TABLET = "DISPERSIBLE_TABLET",
  DROPS = "DROPS",
  ELIXIR = "ELIXIR",
  ENEMA = "ENEMA",
  FLOWER = "FLOWER",
  FOAM = "FOAM",
  GEL = "GEL",
  GRANULATE = "GRANULATE",
  GRANULE = "GRANULE",
  INHALER = "INHALER",
  INJECTION = "INJECTION",
  LIQUID = "LIQUID",
  LINCTUS = "LINCTUS",
  LOZENGE = "LOZENGE",
  LOTION = "LOTION",
  MOUTHWASH = "MOUTHWASH",
  NEBULE = "NEBULE",
  OIL = "OIL",
  OINTMENT = "OINTMENT",
  PASTILLE = "PASTILLE",
  PATCH = "PATCH",
  PLASTER = "PLASTER",
  POWDER = "POWDER",
  PRE_FILLED_PEN = "PRE_FILLED_PEN",
  PRE_FILLED_SYRINGE = "PRE_FILLED_SYRINGE",
  SACHET = "SACHET",
  SOLUTION = "SOLUTION",
  SPRAY = "SPRAY",
  SUGAR_FREE_SUSPENSION = "SUGAR_FREE_SUSPENSION",
  SUPPOSITORIES = "SUPPOSITORIES",
  SUSPENSION = "SUSPENSION",
  SYRUP = "SYRUP",
  TABLET = "TABLET",
  TUBE = "TUBE",
  VAPE_CARTRIDGE = "VAPE_CARTRIDGE",
}

enum Species {
  HYBRID = "HYBRID",
  HYBRID_INDICA = "HYBRID_INDICA",
  HYBRID_SATIVA = "HYBRID_SATIVA",
  INDICA = "INDICA",
  SATIVA = "SATIVA",
}

enum FlowerGrade {
  STANDARD = "STANDARD",
  SMALL_BUD = "SMALL_BUD",
}

export enum SuggestedUse {
  DAY_TIME = "DAY_TIME",
  NIGHT_TIME = "NIGHT_TIME",
}

export enum MicrobeStandard {
  EU_5_1_4 = "EU_5_1_4",
  EU_5_1_8b = "EU_5_1_8b",
  EU_5_1_8c = "EU_5_1_8c",
}

const formulaSchema = z.object({
  id: z.string().uuid(),
  appearance: z.string().nullable(),
  aroma: z.string().nullable(),
  brand: z.string(),
  cbd: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
  cbg: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
  cbn: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
  controlled: z.boolean(),
  cultivator: z.string().nullable(),
  firebaseUid: z.string(),
  flowerGrade: z.nativeEnum(FlowerGrade).nullable(),
  form: z.nativeEnum(Form),
  ingredients: z.array(z.string()),
  intendedUse: z.string().nullable(),
  internalName: z.string(),
  irradiationType: z.string().nullable(),
  isCuraleafBrand: z.boolean(),
  manufacturer: z.string().nullable(),
  market: z.string(),
  microbeStandard: z.nativeEnum(MicrobeStandard).nullable(),
  parentStrain: z.string().nullable(),
  quantityPerCartridge: zodDecimal().nullable(),
  registeredName: z.string(),
  sku: z.string().nullable(),
  species: z.nativeEnum(Species).nullable(),
  state: z.nativeEnum(FormulaState),
  stateStaffId: z.number().int().positive().nullable(),
  stateStaffEmail: z.string().email().nullable(),
  stateTimestamp: z.coerce.date(),
  stateReason: z.string().nullable(),
  strain: z.string().nullable(),
  suggestedUse: z.array(z.nativeEnum(SuggestedUse)),
  terpenes: z.array(z.string()),
  thc: z.tuple([zodDecimal(), zodDecimal()]).nullable(),
});

export const checkIfFormulaIsCannabisBased = (formula: Formula): boolean => {
  if (
    (formula.cbd && formula.cbd[1] > new Decimal(0)) ||
    (formula.cbg && formula.cbg[1] > new Decimal(0)) ||
    (formula.cbn && formula.cbn[1] > new Decimal(0)) ||
    (formula.thc && formula.thc[1] > new Decimal(0))
  ) {
    return true;
  } else {
    return false;
  }
};

export type Formula = z.infer<typeof formulaSchema>;

export const newFormula = (data: unknown) => formulaSchema.parse(data);
