import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";

import {
  newSubscription,
  newSubscriptionHistory,
  Subscription,
  SubscriptionHistory,
  SubscriptionType,
} from "src/models";
import { PaginatedResult, PaginationSettings } from "src/pagination";

export const usePatientSubscriptionsQuery = (patientId: string) => {
  return useQuery<Subscription[]>({
    queryKey: ["subscriptions", patientId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/subscriptions/patient/${patientId}/`,
      );

      return response.data.subscriptions.map((data: unknown) =>
        newSubscription(data),
      );
    },
  });
};

export const useSubscriptionQuery = (subscriptionId: string) => {
  return useQuery<Subscription>({
    queryKey: ["subscriptions", subscriptionId],
    queryFn: async () => {
      const response = await axios.get(`/v1/subscriptions/${subscriptionId}/`);

      return newSubscription(response.data);
    },
  });
};

export const useSubscriptionsQuery = (
  pagination?: Partial<PaginationSettings<Subscription>>,
) => {
  const { ...params } = pagination ?? {};
  return useQuery<PaginatedResult<Subscription>>({
    queryKey: ["subscriptions", pagination],
    queryFn: async () => {
      const response = await axios.get(`/v1/subscriptions/`, {
        params: {
          ...params,
        },
      });

      return {
        rows: response.data.subscriptions.map((data: unknown) =>
          newSubscription(data),
        ),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
    placeholderData: keepPreviousData,
  });
};

export const useGetSubscriptionHistoryQuery = (subscriptionId: string) => {
  return useQuery<SubscriptionHistory[]>({
    queryKey: ["subscriptionHistory", subscriptionId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/subscriptions/${subscriptionId}/history/`,
      );

      return response.data.history.map((data: unknown) =>
        newSubscriptionHistory(data),
      );
    },
  });
};

interface ISubscriptionData {
  patientId: string;
  reason: string;
  stripeId: string | null;
  type: SubscriptionType;
}

export const useCreateSubscriptionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISubscriptionData) => {
      const response = await axios.post("/v1/subscriptions/", data);
      return response.data.id;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscriptions"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["subscriptionHistory"],
      });
    },
  });
};

interface ISubscriptionCancellationData {
  noticePeriod: boolean;
  reason: string | null;
}

export const useCancelSubscriptionMutation = (subscriptionId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISubscriptionCancellationData) => {
      await axios.put(`/v1/subscriptions/${subscriptionId}/cancel/`, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscriptions"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["subscriptionHistory"],
      });
    },
  });
};

export const useOverrideSubscriptionNoticePeriodMutation = (
  subscriptionId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await axios.put(
        `/v1/subscriptions/${subscriptionId}/override-notice-period/`,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscriptions"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["subscriptionHistory"],
      });
    },
  });
};

interface IBillingDayData {
  newBillingDay: number;
  reason: string;
}

export const useChangeSubscriptionBillingDayMutation = (
  subscriptionId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IBillingDayData) => {
      await axios.put(
        `/v1/subscriptions/${subscriptionId}/change-billing-date/`,
        data,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["subscriptions", subscriptionId],
      });
      await queryClient.invalidateQueries({
        queryKey: ["subscriptionPayments"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["subscriptionHistory"],
      });
    },
  });
};
