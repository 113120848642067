import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { LetterType } from "src/models/clinician";
import {
  Prescriber,
  PrescriberType,
  newPrescriber,
} from "src/models/prescriber";
import { useQuery, useMutation } from "src/query";

export const usePrescribersQuery = () =>
  useQuery<Prescriber[]>({
    queryKey: ["prescribers"],
    queryFn: async () => {
      const response = await axios.get("/v1/prescribers/");
      return response.data.prescribers.map((data: unknown) =>
        newPrescriber(data),
      );
    },
  });

export const usePrescriberQuery = (prescriberId: string | undefined) => {
  return useQuery<Prescriber>({
    queryKey: ["prescribers", prescriberId],
    queryFn: async () => {
      const response = await axios.get(`/v1/prescribers/${prescriberId}/`);
      return newPrescriber(response.data);
    },
    enabled: prescriberId !== undefined,
  });
};

export const usePrescriberByClinicianIdQuery = (
  clinicianId: string | undefined | null,
) => {
  return useQuery<Prescriber>({
    queryKey: ["prescribers", clinicianId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/prescribers/clinician/${clinicianId}/`,
      );

      return newPrescriber(response.data);
    },
    enabled: clinicianId !== undefined,
  });
};

export const usePrescriberByStaffIdQuery = () => {
  const { member } = useContext(AuthContext);
  return useQuery<Prescriber>({
    queryKey: ["prescriber", member?.id],
    queryFn: async () => {
      const response = await axios.get(`/v1/prescribers/staff/${member!.id}/`);

      return newPrescriber(response.data);
    },
  });
};

export interface IPrescriberData {
  clinicianId: string;
  fullTitle: string;
  pin: string;
  prescriberType: PrescriberType;
  registrationNumber: string;
  specialism: string;
}

export const useCreatePrescriberMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IPrescriberData) =>
      await axios.post("/v1/prescribers/", data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["prescribers"] });
    },
  });
};

export const useEditPrescriberMutation = (prescriberId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IPrescriberData) =>
      await axios.put(`/v1/prescribers/${prescriberId}/`, data),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["prescribers"] });
    },
  });
};

export interface IPrescriberSignatureData {
  signature: string;
  autoApprovalLetters: LetterType[];
}

export const useAddPrescriberSignatureMutation = (prescriberId: string) => {
  const queryClient = useQueryClient();
  const { member } = useContext(AuthContext);
  return useMutation({
    mutationFn: async (data: IPrescriberSignatureData) =>
      await axios.put(
        `/v1/prescribers/${prescriberId}/update-signature/`,
        data,
      ),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["prescribers"] });
      await queryClient.resetQueries({ queryKey: ["prescriber", member?.id] });
      await queryClient.invalidateQueries({
        queryKey: ["clinicians"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
    },
  });
};
