import { formatDateTime } from "@curaleaf-international/components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { MouseEvent, useContext, useState } from "react";
import { Link as WLink } from "wouter";

import { AuthContext } from "src/AuthContext";
import EmailPreview from "src/components/EmailPreview";
import { Role, RenderedMassEmail, MassEmailState } from "src/models";

interface IProps {
  massEmail: RenderedMassEmail;
}

const MassEmailRow = ({ massEmail }: IProps) => {
  const { member } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDialog = () => {
    setDialogOpen(!dialogOpen);
    handleClose();
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {massEmail.sent === null &&
          member?.roles.includes(Role.CUSTOMER_SERVICE_LEAD) && [
            <MenuItem
              key="edit"
              component={WLink}
              to={`/mass-emails/edit/${massEmail.id}/`}
            >
              Edit
            </MenuItem>,
            <MenuItem
              key="delete"
              component={WLink}
              to={`/mass-emails/delete/${massEmail.id}/`}
            >
              Delete
            </MenuItem>,
          ]}
        <MenuItem onClick={showDialog}>Show Preview</MenuItem>
      </Menu>
      <Dialog open={dialogOpen} onClose={showDialog}>
        <EmailPreview email={massEmail} />
      </Dialog>
      <TableRow key={massEmail.id}>
        <TableCell>{formatDateTime(massEmail.created)}</TableCell>
        <TableCell>
          {massEmail.sent
            ? `Sent on ${formatDateTime(massEmail.sent)}`
            : massEmail.state === MassEmailState.READY
              ? "Queued for sending"
              : "Draft"}
        </TableCell>
        <TableCell>{massEmail.subjectLine}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="more"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default MassEmailRow;
