import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const PatientAppHelp = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 4 }}>
          Patient App FAQs
        </Typography>
        <Typography variant="h6" gutterBottom>
          My verification email link has expired, how do I get another?
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
          If the verification email link has expired, the patient can request a
          new one by clicking the 'Resend verification email' button in their
          account. It'll be what they see when they log in to their account and
          at this stage in their application, it's the only thing they can do
          besides logging out.
        </Typography>
        <Typography variant="h6" gutterBottom>
          My reset password link has expired, how do I get another?
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2 }}>
          Patients can request a reset password link on the{" "}
          <Link href="https://app.curaleafclinic.com/sign-in/">
            sign in page
          </Link>{" "}
          by clicking the 'Forgotten password?' link just beneath where they
          would enter their email and password.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Why do I get a blank screen when trying to answer questionnaires?
        </Typography>
        <Typography variant="body1" gutterBottom>
          The most common reason that patients experience these issues is that
          they are using a VPN when trying to complete questionnaires. Please
          ask if the patient can temporarily disable their VPN and try again. If
          they are not using a VPN or the issue persists, please request they
          try using a different device or browser.
        </Typography>
      </CardContent>
    </Card>
  );
};
export default PatientAppHelp;
