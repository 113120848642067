import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  MassEmailState,
  RenderedMassEmail,
  newRenderedMassEmail,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export interface IMassEmailCreateData {
  subjectLine: string;
  body: string;
}

export interface IMassEmailEditData extends IMassEmailCreateData {
  state: MassEmailState;
}

export const useCreateMassEmailMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IMassEmailCreateData) => {
      const response = await axios.post("/v1/mass-emails/", {
        subjectLine: data.subjectLine,
        body: data.body,
      });
      return response.data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["massEmails"] }),
  });
};

export const useUpdateMassEmailMutation = (massEmailId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IMassEmailEditData) =>
      await axios.put(`/v1/mass-emails/${massEmailId}/`, data),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["massEmails"] }),
  });
};

export const useDeleteMassEmailMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (massEmailId: string) =>
      await axios.delete(`/v1/mass-emails/${massEmailId}/`),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["massEmails"] }),
  });
};

export const useMassEmailsQuery = () => {
  return useQuery<RenderedMassEmail[]>({
    queryKey: ["massEmails"],
    queryFn: async () => {
      const response = await axios.get("/v1/mass-emails/");
      return response.data.massEmails.map((json: unknown) =>
        newRenderedMassEmail(json),
      );
    },
  });
};

export const useMassEmailQuery = (massEmailId: string) => {
  return useQuery<RenderedMassEmail>({
    queryKey: ["massEmails", massEmailId],
    queryFn: async () => {
      const response = await axios.get(`/v1/mass-emails/${massEmailId}/`);
      return newRenderedMassEmail(response.data);
    },
  });
};
