import { SkeletonRow } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import PatientRow from "src/pages/Patients/PatientRow";
import { usePatientSearchQuery } from "src/queries";

interface IProps {
  searchValue: string | null;
  dateOfBirth: Date | null;
}
const PatientsTable = ({ searchValue, dateOfBirth }: IProps) => {
  const { data: patients, isLoading } = usePatientSearchQuery(
    searchValue !== "" ? searchValue : null,
    dateOfBirth ?? null,
  );

  let rows;

  if (isLoading) {
    rows = <SkeletonRow cols={7} />;
  } else if (patients === undefined) {
    rows = [];
  } else {
    if (Object.keys(patients).length === 0) {
      rows = (
        <TableRow>
          <TableCell align="center" colSpan={7}>
            No results from search.
          </TableCell>
        </TableRow>
      );
    } else {
      const filteredPatients = Object.values(patients);
      rows = filteredPatients.map((patient, index) => {
        return <PatientRow key={index} patient={patient} />;
      });
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Date of Birth</TableCell>
            <TableCell>Postcode</TableCell>
            <TableCell>NHS Number</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientsTable;
