import {
  CircularLoader,
  Title,
  useHash,
} from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import { useLocation } from "wouter";

import SpecialityGroupClinicians from "src/pages/SpecialityGroup/SpecialityGroupClinicians";
import SpecialityGroupDetails from "src/pages/SpecialityGroup/SpecialityGroupDetails";
import { useSpecialityGroupQuery } from "src/queries";

type TabState = "details" | "clinicians";
interface IProps {
  specialityGroupId: number;
}

const SpecialityGroup = ({ specialityGroupId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { data: group } = useSpecialityGroupQuery(specialityGroupId);
  const [tab, setTab] = useHash<TabState>("details");

  if (group === undefined) {
    return <CircularLoader />;
  }

  return (
    <>
      <Title
        actions={
          <Button
            onClick={() =>
              setLocation(`/speciality-groups/${specialityGroupId}/edit/`)
            }
            variant="contained"
          >
            Edit
          </Button>
        }
        title={group?.specialityName ?? ""}
        breadcrumbs={[
          { label: "Speciality Groups", to: "/speciality-groups/" },
          { label: `${group.specialityName}` },
        ]}
      />
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: 2,
          }}
        >
          <TabList onChange={(_, value: string) => setTab(value as TabState)}>
            <Tab label="Details" value="details" />
            <Tab label="Clinicians" value="clinicians" />
          </TabList>
        </Box>
        <TabPanel value="details">
          <SpecialityGroupDetails specialityGroupId={specialityGroupId} />
        </TabPanel>
        <TabPanel value="clinicians">
          <SpecialityGroupClinicians specialityGroupId={specialityGroupId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default SpecialityGroup;
