import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { forwardRef, useEffect, useRef } from "react";
import SignaturePad from "react-signature-pad-wrapper";

export class NoSignatureError extends Error {
  constructor(message: string) {
    super(message);
  }
}

const SignatureBox = forwardRef((_props, ref) => {
  const canvasRef = useRef<SignaturePad | null>(null);

  const onClear = () => {
    if (canvasRef.current !== undefined) {
      canvasRef.current?.clear();
    }
  };

  useEffect(() => {
    (ref as any).current = () => {
      if (
        canvasRef.current === undefined ||
        canvasRef.current?.isEmpty() ||
        canvasRef.current === null
      ) {
        throw new NoSignatureError("No signature");
      }

      const base64String = canvasRef.current
        ?.toDataURL("image/png")
        .split(",")[1];

      return base64String;
    };
  }, [canvasRef, ref]);

  return (
    <Stack spacing={2} alignItems={"center"}>
      <Box sx={{ border: 1, width: "340px", align: "center", marginBottom: 2 }}>
        <SignaturePad
          options={{ penColor: "black", backgroundColor: "rgba(0,0,0,0)" }}
          ref={canvasRef}
        />
      </Box>
      <Button
        color="secondary"
        onClick={onClear}
        variant="outlined"
        size="small"
      >
        Clear signature
      </Button>
    </Stack>
  );
});

SignatureBox.displayName = "SignatureBox";

export default SignatureBox;
