import {
  QueryKey,
  UseQueryOptions,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";

import {
  OutOfStockListing,
  OutOfStockListingHistory,
  newOutOfStockListing,
  newOutOfStockListingHistory,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useOutOfStockListingsQuery = (active: boolean) => {
  return useQuery<OutOfStockListing[]>({
    queryKey: ["outOfStockListings", active],
    queryFn: async () => {
      const params = { active };
      const response = await axios.get("/v1/out-of-stock-listings/", {
        params,
      });
      return response.data.listings.map((product: unknown) => {
        return newOutOfStockListing(product);
      });
    },
  });
};

export const useOutOfStockListingQuery = (
  listingId: string | undefined,
  options?: Omit<
    UseQueryOptions<any, any, OutOfStockListing, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  return useQuery<OutOfStockListing>({
    ...options,
    queryKey: ["outOfStockListings", { listingId: listingId ?? "" }],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/out-of-stock-listings/${listingId}/`,
      );
      return newOutOfStockListing(response.data);
    },
    enabled: listingId !== undefined,
  });
};

export interface IOutOfStockListingData {
  productId: string;
  alternativeProducts: string[];
  patientInformation: string | null;
  internalInformation: string | null;
}

export const useCreateOutOfStockListingMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IOutOfStockListingData) =>
      await axios.post("/v1/out-of-stock-listings/", data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["outOfStockListings"] });
      await queryClient.invalidateQueries({
        queryKey: ["outOfStockListingHistory"],
      });
    },
  });
};

export const useEditOutOfStockListingMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IOutOfStockListingData) =>
      await axios.put(`/v1/out-of-stock-listings/${id}/`, data),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["outOfStockListings"] });
      await queryClient.invalidateQueries({
        queryKey: ["outOfStockListingHistory"],
      });
    },
  });
};

export const useCloseOutOfStockListingMutation = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(`/v1/out-of-stock-listings/${id}/close/`),
    onSuccess: async () =>
      await queryClient.invalidateQueries({ queryKey: ["outOfStockListings"] }),
  });
};

export const useOutOfStockListingHistoryQuery = (
  listingId: string | undefined,
  options?: Omit<
    UseQueryOptions<any, any, OutOfStockListingHistory[], QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  return useQuery<OutOfStockListingHistory[]>({
    ...options,
    queryKey: ["outOfStockListingHistory", { listingId: listingId ?? "" }],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/out-of-stock-listings/${listingId}/history/`,
      );
      return response.data.history.map((record: unknown) => {
        return newOutOfStockListingHistory(record);
      });
    },
    enabled: listingId !== undefined,
  });
};
