interface IProps {
  color?: string;
  height: number;
}

const IconLogo = ({ height, color = "currentColor" }: IProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="922.458"
    height={height}
    viewBox="0 0 922.458 654.053"
  >
    <g transform="translate(-447.222 -212.974)">
      <g transform="translate(381 -79.659)">
        <g transform="translate(66.222 292.633)">
          <g transform="translate(0 0)">
            <path
              fill={color}
              d="M604.92,173.37c-20.091-44.663-45.9-88.845-83-121.657-11.07-9.835-24.642-19.606-38.242-24.867l15.162-2.612A426.665,426.665,0,0,0,456.559,74.4c-33.185,46.108-58.094,98.816-66.489,154.957a316.793,316.793,0,0,0-3.071,35.9c.138,6.187,0,14.028.249,20.185,1.765,48.277,16.606,96.754,46.931,135.225a225.984,225.984,0,0,0,35.07,35.623c3.659,2.82,9.5,7.758,13.507,10.233a90.88,90.88,0,0,0,9.158,5.424,3.364,3.364,0,0,0,4.735-1.917,3.72,3.72,0,0,1-2.786,3.29c-1.489.5-3.136-.1-4.616-.542-8.166-2.242-16.671-6.517-24.137-10.53-99.986-53.981-128.167-178.613-99.48-281.056,15.77-57.1,47.492-109.26,88.188-152.539A329.343,329.343,0,0,1,479.582,3.908,13.455,13.455,0,0,1,494.744,1.3c61.625,31.357,98.322,108.062,110.174,172.078"
              transform="translate(-28.573 -0.027)"
            ></path>
            <path
              fill={color}
              d="M445.957,508.037c-11.052,3.354-22.628,4.321-34.15,4.419a266.816,266.816,0,0,1-34.656-1.555c-69.651-8.151-139.635-36.861-186.778-89.5-17.781-19.7-30.96-43.216-43.161-66.555-2.933-5.134-8.6-18.214-11.218-23.683-3.807-9.346-9.333-23.312-12.293-32.866-12.9-38.832-17.756-80.484-16.128-121.26.212-4.564.184-12.166.175-16.722a16.7,16.7,0,0,1,16.515-16.62c6.207-.253,20.734-.307,26.656-.479,38.748.452,77.7,4.863,115.093,15.516,47.685,13.694,92.712,38.4,127.792,73.253,41.009,40.522,68.07,93.636,79.923,149.245C480.4,414.707,484.09,449.9,476.733,483.5c1.334-22.417-2.327-44.9-7.182-66.69-9.94-43.081-27.437-84.356-53.45-120.32C351.968,207.069,250.074,175.224,142.459,177.24l-16.683.361,16.5-17.382c.11,11.154-.579,23.023-.6,34.05-.7,50.869,11.761,101.419,33.239,147.6,3.365,6.806,7.062,15.384,10.841,21.947,7.173,13.686,15.466,28.654,25.4,40.6,33.985,42.62,83.435,70.876,135.276,88.088a323.485,323.485,0,0,0,65.632,14.869c11.319,1.546,22.477,2.115,33.893.66"
              transform="translate(-8.63 -13.787)"
            ></path>
            <path
              fill={color}
              d="M343.477,653.865c-37.368,24.957-83.213,37.151-128.389,35.388-45.809-1.277-90.727-15.005-131.534-34.764a479.167,479.167,0,0,1-77.02-47.175,16.022,16.022,0,0,1-3.2-22.706c45-55.346,99.848-106.318,167.312-133.914,58.239-23.891,123.072-25.852,181.311-.922,26.5,11.154,50.214,27.623,72.355,45.358,8.579,7.032,17.167,14.625,25.167,22.309,2.74,2.553,5.627,5.252,7.117,8.838,3.54,8.605-16,9.175-21,9.78,5.728-1.768,12.046-3.272,16.818-6.815.4-.344.653-.768.579-.8,0-.018-.046.018-.055.063-1.692-2.6-4.717-4.637-7.438-6.426-33.7-22.625-69.45-44.02-108.62-55.039-54.275-15.149-110.235-8.089-160.465,16.379-38.315,18.259-72.181,44.879-102.708,74.2C58.352,572.558,43.66,588.3,29.618,604.7l-2.731-23.294c11.273,8.759,22.51,17.185,34.364,25.084,84.686,56.91,183.255,88.6,282.239,47.365"
              transform="translate(0.007 -41.528)"
            ></path>
            <path
              fill={color}
              d="M561.129,606.129c38.6,29.142,82.056,52.5,130.191,60.155,90.5,14.933,180.249-24,251.39-76.887l-3.8,18.5-5.159-9.745-5.627-9.59c-3.246-5.586-8.9-13.17-12.662-18.56-27.8-37.25-63.712-68.083-102.493-94.359C867.353,493.688,916.785,527.73,951.5,573c4.487,5.821,10.178,14.58,14.04,20.926a13.612,13.612,0,0,1-2.979,17.835c-53.468,45.991-119.746,80.624-191.338,86.839-83.213,8.225-160.563-26.684-210.1-92.47"
              transform="translate(-45.167 -45.731)"
            ></path>
            <path
              fill={color}
              d="M893.689,141.321c-6.74-6.671-59.105,16.105-112.982,20.039s-125.741,5.026-203.352,75.016c-103.471,93.365-56.383,235.477-52.046,225.572s-25.626-108.976,70.213-196.6,233.718-70.324,233.718-70.324S814.005,346.915,732.906,418.55A221.757,221.757,0,0,1,591.322,480.1l4.166-16.777a328.549,328.549,0,0,1,11.438-43.614A153.044,153.044,0,0,1,627.4,381.725a250.258,250.258,0,0,1,29.424-33c2.648-2.567,5.259-5.315,8.119-7.729.579-.551,6.648-5.55,4.818-6.58-1.315-.75-3.5-.108-4.827.253a50.313,50.313,0,0,0-9.581,3.751,109.286,109.286,0,0,0-13.314,7.665,161.352,161.352,0,0,0-16.008,12.374,196.778,196.778,0,0,0-17.682,17.635,243.175,243.175,0,0,0-18.307,23.574A289.208,289.208,0,0,0,572.114,429.8c-7.761,14.987-13.5,30.788-20.909,45.811a314.645,314.645,0,0,1-26.444,43.777,48.149,48.149,0,0,1-31.826,21.826,81.486,81.486,0,0,1-15.684,1.438,60.954,60.954,0,0,0-30.289,7.439,36.958,36.958,0,0,0-12,49.354c9.728,16.044,37.046,17.087,53.432,12.149a112.7,112.7,0,0,0,46.851-36.7l.735-.768,1.673-1.971,2.565-3.191,3.283-4.1,3.853-4.79c1.389-1.754,2.8-3.489,4.2-5.224l4.349-5.487,4.349-5.541c1.463-1.768,2.869-3.571,4.349-5.342l3.982-4.926,3.475-4.339,2.777-3.489,1.5-1.853a6.5,6.5,0,0,1,6.051-3.143,37.839,37.839,0,0,1,4.175-.09l1.655-.073c.606.018,1.278-.144,1.9-.127a351.336,351.336,0,0,0,55.242-9.745,273.534,273.534,0,0,0,75.261-31.022,177.893,177.893,0,0,0,25.24-18.874c93.476-84.326,106.349-186.3,120.6-234.221s33.948-78.487,27.208-85.175"
              transform="translate(-34.616 -13.488)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconLogo;
