import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import {
  AppointmentCredit,
  AppointmentType,
  newAppointmentCredit,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useAppointmentsCreditsQuery = (appointmentId: string) => {
  return useQuery<AppointmentCredit[]>({
    queryKey: ["appointmentsCredits", appointmentId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-credits/appointment/${appointmentId}/`,
      );
      return response.data.credits.map((json: unknown) =>
        newAppointmentCredit(json),
      );
    },
    enabled: appointmentId !== undefined,
  });
};

export const useAppointmentCreditQuery = (appointmentCreditId: string) => {
  return useQuery<AppointmentCredit>({
    queryKey: ["appointmentCredits", appointmentCreditId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-credits/${appointmentCreditId}/`,
      );
      return newAppointmentCredit(response.data);
    },
    enabled: appointmentCreditId !== undefined,
  });
};

export const usePatientsAppointmentCreditsQuery = (
  patientId: string | undefined,
) => {
  return useQuery<AppointmentCredit[]>({
    queryKey: ["appointmentCredits", "patient", patientId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/appointment-credits/patient/${patientId}/`,
      );
      return response.data.credits.map((json: unknown) =>
        newAppointmentCredit(json),
      );
    },
    enabled: patientId !== undefined,
  });
};

interface ICreditData {
  activeFrom: Date;
  activeTo: Date;
  appointmentType: AppointmentType;
  patientId: string;
  reason: string;
}

export const useCreateAppointmentCreditMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ICreditData) => {
      const response = await axios.post("/v1/appointment-credits/", {
        activeFrom: formatISO(data.activeFrom),
        activeTo: formatISO(data.activeTo),
        appointmentType: data.appointmentType,
        patientId: data.patientId,
        reason: data.reason,
      });
      return response.data.id;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["appointmentCredits"],
      });
    },
  });
};

interface ICancelCreditData {
  reason: string;
}

export const useCancelAppointmentCreditMutation = (
  appointmentCreditId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ICancelCreditData) => {
      await axios.put(
        `/v1/appointment-credits/${appointmentCreditId}/cancel/`,
        {
          reason: data.reason,
        },
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["appointmentCredits"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["appointmentCredits", appointmentCreditId],
      });
      await queryClient.invalidateQueries({
        queryKey: ["appointmentCreditHistory", appointmentCreditId],
      });
    },
  });
};

interface IEditCreditData {
  activeTo: Date;
  appointmentType: AppointmentType;
  reason: string;
}

export const useEditAppointmentCreditMutation = (
  appointmentCreditId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IEditCreditData) => {
      await axios.put(`/v1/appointment-credits/${appointmentCreditId}/edit/`, {
        activeTo: formatISO(data.activeTo),
        appointmentType: data.appointmentType,
        reason: data.reason,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["appointmentCredits"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["appointmentCredits", appointmentCreditId],
      });
      await queryClient.invalidateQueries({
        queryKey: ["appointmentCreditHistory", appointmentCreditId],
      });
    },
  });
};
