import { useQuery } from "@curaleaf-international/components";
import axios from "axios";

import { FormulaStateHistory, newFormulaStateHistory } from "src/models";

export const useFormulaStateHistoryQuery = (formulaId: string) => {
  return useQuery<FormulaStateHistory[]>({
    queryKey: ["formulaStateHistory", formulaId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/formula-state-history/formula/${formulaId}/`,
      );
      return response.data.history.map((data: unknown) =>
        newFormulaStateHistory(data),
      );
    },
  });
};
