import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { SpecialityGroup, newSpecialityGroup, Diagnosis } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useSpecialityGroupsQuery = () => {
  return useQuery<SpecialityGroup[]>({
    queryKey: ["specialityGroups"],
    queryFn: async () => {
      const response = await axios.get(`/v1/speciality-groups/`);
      return response.data.groups.map((data: unknown) =>
        newSpecialityGroup(data),
      );
    },
  });
};

export const useSpecialityGroupQuery = (
  specialityGroupId: number | undefined,
) => {
  return useQuery<SpecialityGroup>({
    queryKey: ["specialityGroups", specialityGroupId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/speciality-groups/${specialityGroupId}/`,
      );

      return newSpecialityGroup(response.data);
    },
    enabled: specialityGroupId !== undefined,
  });
};

interface ISpecialityGroupData {
  associatedDiagnoses: Diagnosis[];
  clinicians: string[];
  complexClinicians: string[];
  specialityName: string;
}

export const useCreateSpecialityGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISpecialityGroupData) => {
      const response = await axios.post(`/v1/speciality-groups/`, data);

      return response.data.id;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["specialityGroups"] });
    },
  });
};

export const useEditSpecialityGroupMutation = (specialityGroupId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ISpecialityGroupData) => {
      const response = await axios.put(
        `/v1/speciality-groups/${specialityGroupId}/`,
        data,
      );

      return newSpecialityGroup(response.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["specialityGroups"] });
      await queryClient.invalidateQueries({
        queryKey: ["specialityGroupClinicians", specialityGroupId],
      });
    },
  });
};
