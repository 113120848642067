import { Title, useHash } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Tab from "@mui/material/Tab";
import { useState } from "react";

import TravelLetterActions from "src/components/TravelLetterActions";
import TravelLetterDetails from "src/pages/TravelLetter/TravelLetterDetails";
import TravelLetterEmails from "src/pages/TravelLetter/TravelLetterEmails";
import TravelLetterStateHistory from "src/pages/TravelLetter/TravelLetterStateHistory";
import { usePatientQuery, useTravelLetterQuery } from "src/queries";

type TabState = "details" | "history" | "emails";

interface IProps {
  travelLetterId: string;
}

const TravelLetter = ({ travelLetterId }: IProps) => {
  const { data: letter } = useTravelLetterQuery(travelLetterId);
  const { data: patient } = usePatientQuery(letter?.patientId);
  const [tabState, setTab] = useHash<TabState>("details");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        {letter && patient ? <TravelLetterActions letter={letter} /> : null}
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          {
            to: "/travel-letters/",
            label: "Travel Letters",
          },
          { label: `Travel Letter - ${patient?.name ?? ""}` },
        ]}
        title={`Travel Letter - ${patient?.name ?? ""}`}
      />
      <TabContext value={tabState}>
        <TabList
          onChange={(_, value: string) => setTab(value as TabState)}
          sx={{ marginBottom: 2 }}
        >
          <Tab label="Details" value="details" />
          <Tab label="History" value="history" />
          <Tab label="Emails" value="emails" />
        </TabList>
        <TabPanel value="details">
          <TravelLetterDetails letterId={travelLetterId} />
        </TabPanel>
        <TabPanel value="history">
          <TravelLetterStateHistory travelLetterId={travelLetterId} />
        </TabPanel>
        <TabPanel value="emails">
          <TravelLetterEmails letterId={travelLetterId} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default TravelLetter;
