import {
  SubmitButton,
  FormLinks,
  CheckboxField,
  AutocompleteField,
  TextField,
  sortByKey,
  SelectField,
  NumberField,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";

import {
  ClinicianSpecialism,
  ClinicianType,
  FollowUpPreference,
  LetterType,
} from "src/models/clinician";
import { Role, Staff } from "src/models/staff";
import { useAllStaffQuery } from "src/queries";
import { convertEnumValueToReadableString, getNameFromEmail } from "src/utils";

export interface IAppointmentTypeRecord {
  [key: string]: boolean;
}

const FormSchema = z.object({
  appointmentTypes: z.record(z.string(), z.boolean()),
  clinicianType: z.nativeEnum(ClinicianType),
  minimumHoursBeforeBooking: z.coerce.number().int().positive(),
  name: z.string(),
  followUpPreference: z.nativeEnum(FollowUpPreference),
  autoApprovalLetters: z.array(z.nativeEnum(LetterType)),
  specialisms: z.array(z.nativeEnum(ClinicianSpecialism)),
  staffId: z.string(),
});
export type FormType = z.input<typeof FormSchema>;
export type ValidatedType = z.infer<typeof FormSchema>;

interface IProps {
  back: string;
  onSubmit: (data: ValidatedType) => Promise<any>;
  submitLabel: string;
  types: string[];
  initialValues: FormType;
  editing?: boolean;
}

const ClinicianForm = ({
  back,
  initialValues,
  onSubmit,
  submitLabel,
  types,
  editing = false,
}: IProps) => {
  const { data: staff } = useAllStaffQuery();

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  const sortKey = (staff: Staff) => {
    return [staff.created];
  };

  let filteredStaff: Staff[] = [];
  if (staff !== undefined) {
    filteredStaff = Object.values(staff)
      .filter(
        (staff) =>
          staff.roles.includes(Role.CONSULTANT) ||
          staff.roles.includes(Role.PHARMACIST) ||
          staff.roles.includes(Role.GENERAL_PRACTITIONER),
      )
      .sort(sortByKey(sortKey, "desc"));
  }

  const values = methods.watch();

  useEffect(() => {
    if (editing === false && staff) {
      const staffMember = Object.values(staff).find(
        (staff) => staff.id === parseInt(values.staffId),
      );
      if (staffMember) {
        const name = getNameFromEmail(staffMember.email);
        methods.setValue("name", name);
      }
    }
  }, [values.staffId]);

  useEffect(() => {
    if (values.clinicianType !== ClinicianType.CONSULTANT) {
      methods.setValue("followUpPreference", FollowUpPreference.NOT_APPLICABLE);
    } else {
      methods.setValue("followUpPreference", initialValues.followUpPreference);
    }
  }, [values.clinicianType]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid size={12}>
                <Typography gutterBottom variant="body2">
                  Staff must be assigned the Consultant, Pharmacist or General
                  Practitioner role to be added as a Clinician.
                </Typography>
                <AutocompleteField
                  fullWidth
                  label={"Staff Member"}
                  name={"staffId"}
                  options={filteredStaff.map((staffMember) => ({
                    label: `${getNameFromEmail(staffMember.email)} (${staffMember.email})`,
                    value: staffMember.id.toString(),
                  }))}
                />
              </Grid>
              <Grid size={12}>
                <TextField fullWidth required label="Name" name="name" />
              </Grid>
              <Grid size={12}>
                <SelectField
                  label="Clinician Type"
                  name="clinicianType"
                  options={Object.values(ClinicianType).map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  required
                  fullWidth
                />
              </Grid>
              <Grid size={12}>
                <Stack
                  width="100%"
                  direction={"row"}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: 1,
                  }}
                >
                  <FormLabel required>Appointment Types</FormLabel>
                  {types.map((type) => (
                    <CheckboxField
                      key={type}
                      name={`appointmentTypes.${type}`}
                      label={type
                        .replace("_", "-")
                        .toLowerCase()
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    />
                  ))}
                </Stack>
              </Grid>
              <Grid size={12}>
                <SelectField
                  label="Specialisms"
                  name="specialisms"
                  options={Object.values(ClinicianSpecialism).map(
                    (specialism) => ({
                      label: specialism,
                      value: specialism,
                    }),
                  )}
                  multiple
                  fullWidth
                />
              </Grid>
              <Grid size={12}>
                <NumberField
                  label="Minimum Hours Before Booking"
                  name="minimumHoursBeforeBooking"
                  fullWidth
                />
              </Grid>
              <Grid size={12}>
                <SelectField
                  label="Follow-Up Preference"
                  name="followUpPreference"
                  options={Object.values(FollowUpPreference).map(
                    (preference) => ({
                      label: convertEnumValueToReadableString(preference, " "),
                      value: preference,
                    }),
                  )}
                  required
                  disabled={values.clinicianType !== ClinicianType.CONSULTANT}
                  fullWidth
                />
              </Grid>
              <Grid size={12}>
                <SelectField
                  fullWidth
                  label="Pre-Authorised Letters"
                  multiple={true}
                  name="autoApprovalLetters"
                  options={Object.keys(LetterType).map((letter) => ({
                    label: letter,
                    value: letter,
                  }))}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label={submitLabel} />
            <FormLinks
              links={[
                {
                  label: "Back",
                  to: back,
                },
              ]}
            />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};
export default ClinicianForm;
