import { Title, ToastContext } from "@curaleaf-international/components";
import {
  SubmitButton,
  TextField,
  FormLinks,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { useCreateMassEmailMutation } from "src/queries/massEmails";
import { useMassEmailsQuery } from "src/queries/massEmails";

const FormSchema = z.object({
  subjectLine: z.string().min(1),
  body: z.string().min(1),
});

type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CreateMassEmail = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: massEmails } = useMassEmailsQuery();
  const { mutateAsync: createMassEmail } = useCreateMassEmailMutation();

  const methods = useForm<FormType>({
    defaultValues: { subjectLine: "", body: "" },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      const response = await createMassEmail(data);
      setLocation(`/mass-emails/edit/${response.id}/`);
      addToast(
        "Mass email draft created, please preview and then mark for sending.",
        "success",
      );
    } catch {
      addToast("Try Again", "error");
    }
  };

  return (
    <FormProvider {...methods}>
      <Title title="Create a Mass Email" />

      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            {massEmails && massEmails[0]?.sent === null ? (
              <Alert severity="error">
                There is already a mass email awaiting sending in the queue. If
                you create another one and leave more than one email pending, it
                will result in patients receiving multiple emails from us at the
                same time. <br />
                <br />
                As a reminder, emails that have been queued for more than 1 hour
                are sent automatically on the hour, between 9am and 5pm Monday
                to Friday.
              </Alert>
            ) : (
              <Alert severity="warning">
                Do not use this for marketing or advertising emails. It will
                degrade our email rating and make all our emails (including
                payment links) more likely to be classified as spam. <br />
                <br />
                Once created successfully, the email will be placed in the queue
                and will send on the hour between 9am and 5pm during Monday to
                Friday, after it has been in the queue for more than 1 hour.
              </Alert>
            )}
            <TextField
              fullWidth
              label="Subject Line"
              name="subjectLine"
              required
            />
            <TextField
              fullWidth
              label="Email Body"
              name="body"
              multiline={true}
              rows={8}
              required
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Create draft & preview" disableClean={true} />
            <FormLinks links={[{ label: "Back", to: "/tools/#mass-emails" }]} />
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default CreateMassEmail;
