import {
  SkeletonRow,
  formatCurrency,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  AppointmentCredit,
  AppointmentPayment,
  PaymentAction,
} from "src/models";
import {
  useAppointmentsCreditsQuery,
  useAppointmentsPaymentsQuery,
} from "src/queries";

interface IProps {
  appointmentId: string;
}

const AppointmentPaymentsTable = ({ appointmentId }: IProps) => {
  const { data: payments } = useAppointmentsPaymentsQuery(appointmentId);
  const { data: credits } = useAppointmentsCreditsQuery(appointmentId);

  return (
    <>
      <Card sx={{ marginBottom: 2 }}>
        <CardHeader title="Payments" />
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Processor</TableCell>
                <TableCell>Xero ID</TableCell>
                <TableCell>Xero Payment ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments === undefined ? (
                <SkeletonRow cols={8} />
              ) : payments.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>No payments found.</TableCell>
                </TableRow>
              ) : (
                payments.map((payment: AppointmentPayment) => {
                  return (
                    <TableRow key={payment.id}>
                      <TableCell>{payment.id}</TableCell>
                      <TableCell>{payment.action}</TableCell>
                      <TableCell>{formatCurrency(payment.amount)}</TableCell>
                      <TableCell>{payment.state}</TableCell>
                      <TableCell>
                        {payment.reasonForRefund ?? payment.stateReason}
                      </TableCell>
                      <TableCell>
                        {payment.processor}
                        <br />
                        {payment.stripeId ?? payment.vivaId}
                      </TableCell>
                      <TableCell>
                        <Link
                          href={
                            payment.action === PaymentAction.SALE
                              ? `https://go.xero.com/app/!y1hh!/invoicing/view/${payment.xeroId}`
                              : `https://go.xero.com/AccountsReceivable/ViewCreditNote.aspx?creditNoteID=${payment.xeroId}`
                          }
                          target="_blank"
                        >
                          {payment.xeroId}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          href={`https://go.xero.com/Bank/ViewTransaction.aspx?bankTransactionID=${payment.xeroPaymentId}`}
                          target="_blank"
                        >
                          {payment.xeroPaymentId}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Card>
        <CardHeader title="Credits" />
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Reason</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {credits === undefined ? (
                <SkeletonRow cols={2} />
              ) : credits.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2}>No credits found.</TableCell>
                </TableRow>
              ) : (
                credits.map((credit: AppointmentCredit) => {
                  return (
                    <TableRow key={credit.id}>
                      <TableCell>{credit.id}</TableCell>
                      <TableCell>{credit.currentHistoryReason}</TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default AppointmentPaymentsTable;
