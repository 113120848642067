import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { FP10, FP10State, newFP10 } from "src/models";
import { PaginatedResult, PaginationSettings } from "src/pagination";
import { useMutation, useQuery } from "src/query";

export const useFp10sQuery = (
  pagination?: Partial<PaginationSettings<FP10>>,
  state?: FP10State,
) => {
  const { rawSearchQuery: _ = "", ...params } = pagination ?? {};
  return useQuery<PaginatedResult<FP10>>({
    queryKey: ["fp10s", pagination, state],
    queryFn: async () => {
      const response = await axios.get("/v1/fp10s/", {
        params: {
          ...params,
          state,
        },
      });
      return {
        rows: response.data.fp10s.map((data: unknown) => newFP10(data)),
        totalRecordCount: response.data.totalRecordCount,
        paginationSettings: response.data.paginationSettings,
      };
    },
    placeholderData: keepPreviousData,
  });
};

export const useFP10Query = (fp10Id: string) => {
  return useQuery<FP10>({
    queryKey: ["fp10s", fp10Id],
    queryFn: async () => {
      const response = await axios.get(`/v1/fp10s/${fp10Id}/`);
      return newFP10(response.data);
    },
    enabled: fp10Id !== undefined,
  });
};

export interface ICreateAndDestroyFP10Data {
  serial: string;
  reason: string;
  witnessId: number;
}

export const useCreateAndDestroyFP10Mutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ICreateAndDestroyFP10Data) =>
      await axios.post(`/v1/fp10s/destroy/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["fp10s"] });
    },
  });
};
