import { alpha } from "@mui/material/styles";
import { darken, lighten, Theme } from "@mui/system";

import { Appointment, AppointmentType } from "src/models/appointment";
import { ClinicianAvailability } from "src/models/clinicianAvailability";
import { ClinicianUnavailability } from "src/models/clinicianUnavailability";

export const SX_INFO = {
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === "light"
      ? lighten(theme.palette.info.main, 0.75)
      : darken(theme.palette.info.main, 0.75),
  color: (theme: Theme) =>
    theme.palette.mode === "light"
      ? darken(theme.palette.info.main, 0.75)
      : lighten(theme.palette.info.main, 0.75),
};

export const SX_WARNING = {
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === "light"
      ? lighten(theme.palette.warning.main, 0.9)
      : darken(theme.palette.warning.main, 0.9),
  color: (theme: Theme) =>
    theme.palette.mode === "light"
      ? darken(theme.palette.warning.main, 0.6)
      : lighten(theme.palette.warning.main, 0.6),
};

export const SX_ERROR = {
  backgroundColor: (theme: Theme) =>
    theme.palette.mode === "light"
      ? lighten(theme.palette.error.main, 0.9)
      : darken(theme.palette.error.main, 0.9),
  color: (theme: Theme) =>
    theme.palette.mode === "light"
      ? darken(theme.palette.error.main, 0.6)
      : lighten(theme.palette.error.main, 0.6),
};

export const getAppointmentColour = (
  appointment: Appointment,
  theme: Theme,
) => {
  let eventColour;
  switch (appointment.type) {
    case AppointmentType.CHECK_UP:
      eventColour = theme.palette.primary.main;
      break;
    case AppointmentType.FOLLOW_UP:
      eventColour = theme.palette.secondary.main;
      break;
    case AppointmentType.INITIAL:
      eventColour = theme.palette.secondary.light;
      break;
    default:
      eventColour = theme.palette.primary.dark;
  }
  return eventColour;
};

export const getClinicianAvailabilityEvents = (
  events: ClinicianAvailability[],
  theme: Theme,
) => {
  const availabilityEvents = events?.map((availability) => {
    return {
      id: availability.id.toString(),
      title: `${availability.appointmentTypes.map((type) =>
        convertEnumValueToReadableString(type + "s", "-"),
      )}`,
      start: availability.startAt,
      end: availability.endAt,
      backgroundColor:
        availability.recurringId !== null
          ? alpha(theme.palette.primary.light, 0.5)
          : alpha(theme.palette.secondary.light, 0.5),
      display: "block",
      extendedProps: {
        recurring: availability.recurringId ? true : false,
      },
    };
  });
  return availabilityEvents;
};

export const getClinicianUnavailabilityEvents = (
  events: ClinicianUnavailability[],
  theme: Theme,
) => {
  const unavailabilityEvents = events?.map((unavailability) => {
    return {
      id: unavailability.startAt.toString(),
      title: `Unavailable - ${unavailability.reason}`,
      start: unavailability.startAt,
      end: unavailability.endAt,
      backgroundColor: theme.palette.action.disabledBackground,
      display: "background",
      extendedProps: {
        recurring: false,
      },
    };
  });
  return unavailabilityEvents;
};

export const convertEnumValueToReadableString = (
  value: string,
  separator: string,
) => {
  return value
    .toLowerCase()
    .replaceAll("_", separator)
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getOrdinalSuffix = (day: number) => {
  const j = day % 10,
    k = day % 100;
  if (j === 1 && k !== 11) return day + "st";
  if (j === 2 && k !== 12) return day + "nd";
  if (j === 3 && k !== 13) return day + "rd";
  return day + "th";
};

export const getNameFromEmail = (email: string) => {
  const nameEnds = email.indexOf("@");
  const name = email
    .substring(0, nameEnds)
    .replace(".", " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
  return name;
};
