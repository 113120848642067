import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { formatISO } from "date-fns";

import {
  Email,
  newEmail,
  Itinerary,
  TravelLetter,
  TravelLetterHistory,
  TravelLetterState,
  newTravelLetter,
  newTravelLetterHistory,
  newTravelLetterId,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useTravelLettersQuery = (state?: TravelLetterState) => {
  return useQuery<TravelLetter[]>({
    queryKey: ["travelLetters"],
    queryFn: async () => {
      const params = { state };
      const response = await axios.get(`/v1/travel-letters/`, { params });

      return response.data.letters.map((data: unknown) =>
        newTravelLetter(data),
      );
    },
  });
};

export const useTravelLetterQuery = (travelLetterId: string) => {
  return useQuery<TravelLetter>({
    queryKey: ["travelLetters", travelLetterId],
    queryFn: async () => {
      const response = await axios.get(`/v1/travel-letters/${travelLetterId}/`);

      return newTravelLetter(response.data);
    },
  });
};

export const useTravelLetterEmailsQuery = (letterId: string) =>
  useQuery<Email[]>({
    queryKey: ["letterEmails", letterId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/travel-letter-emails/letter/${letterId}/`,
      );

      return response.data.emails.map((data: unknown) => newEmail(data));
    },
  });

export const usePatientTravelLettersQuery = (patientId: string) => {
  return useQuery<TravelLetter[]>({
    queryKey: ["travelLetters", patientId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/travel-letters/patient/${patientId}/`,
      );

      return response.data.letters.map((data: unknown) =>
        newTravelLetter(data),
      );
    },
  });
};

interface ITravelLetterData {
  itinerary: Itinerary[];
  patientId: string;
  prescriptions: string[];
}

export const useCreateTravelLetterMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (rawData: ITravelLetterData) => {
      const formattedData = {
        ...rawData,
        itinerary: rawData.itinerary.map((item) => ({
          ...item,
          arrival: formatISO(new Date(item.arrival), {
            representation: "date",
          }),
          departure: formatISO(new Date(item.departure), {
            representation: "date",
          }),
        })),
      };

      const response = await axios.post(`/v1/travel-letters/`, formattedData);
      return newTravelLetterId(response.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
    },
  });
};

export const useEditTravelLetterMutation = (travelLetterId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: ITravelLetterData) => {
      const formattedData = {
        ...data,
        itinerary: data.itinerary.map((item) => ({
          ...item,
          arrival: formatISO(new Date(item.arrival), {
            representation: "date",
          }),
          departure: formatISO(new Date(item.departure), {
            representation: "date",
          }),
        })),
      };
      return await axios.put(
        `/v1/travel-letters/${travelLetterId}/`,
        formattedData,
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterPrescriptions", travelLetterId],
      });
    },
  });
};

export const useApproveTravelLetterMutation = (travelLetterId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.put(`/v1/travel-letters/${travelLetterId}/approve/`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
    },
  });
};

interface CancellationReason {
  reason: string;
}

export const useCancelTravelLetterMutation = (travelLetterId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CancellationReason) =>
      await axios.put(`/v1/travel-letters/${travelLetterId}/cancel/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
    },
  });
};

export const useUploadTravelLetterMutation = (letterId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileList: File[]) => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("file", file));
      await axios.post(`/v1/travel-letters/${letterId}/file/`, formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
    },
  });
};

export const useGetTravelLetterHistoryQuery = (letterId: string) => {
  return useQuery<TravelLetterHistory[]>({
    queryKey: ["travelLetterHistory", letterId],
    queryFn: async () => {
      const response = await axios.get(
        `/v1/travel-letters/${letterId}/history/`,
      );

      return response.data.history.map((data: unknown) =>
        newTravelLetterHistory(data),
      );
    },
  });
};

interface AssignmentData {
  staffId: number;
}

export const useAssignTravelLetterMutation = (letterId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: AssignmentData) =>
      await axios.patch(`/v1/travel-letters/${letterId}/assign/`, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
    },
  });
};

export const useRemoveAssignmentTravelLetterMutation = (letterId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.patch(`/v1/travel-letters/${letterId}/remove-assignment/`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
    },
  });
};

export const useGenerateTravelLetterMutation = (letterId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () =>
      await axios.post(`/v1/travel-letters/${letterId}/generate/`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["travelLetters"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["travelLetterHistory"],
      });
    },
  });
};
